<template>
	<source v-if="src === null || src === undefined" :media="media" :srcset="fallback" />
	<source v-else :media="media" :srcset="String(image?.srcset)" :sizes="image?.sizes" />
</template>

<script setup lang="ts">
const props = defineProps<{
	src: string | null | undefined | number,
	media?: string,
	sizes?: string,
	fallback?: string,
	preload?: boolean,
}>();

const { getSizes } = useImage();
const image = computed(() => {
	if (props.src === null || props.src === undefined) return
	return getSizes(String(props.src) ?? '', { sizes: props.sizes })
});

if (props.preload && image.value) {
	useHead({
		link: [{ rel: 'preload', href: image.value.src, imagesizes: image.value.sizes, imagesrcset: image.value.srcset, as: 'image', media: props.media }]
	})
}
</script>
